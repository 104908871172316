.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: "Overpass", sans-serif;
    font-size: 200px;
    background-color: #282d42;
    z-index: 1;
    overflow: hidden;
    position: fixed;
}

.subtitles {
    /* position: fixed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 25px;
    text-align: center;
    font-family: "CirrusCumulus", sans-serif;
}

.fullstack {
    align-self: center;
    width: 100%;
    text-align: center;
    font-family: "Rajdhani", sans-serif;
}

.title {
    height: 100%;
    bottom: 5%;
    text-align: center;
    width: 100%;
    z-index: 1;
    font-size: 275px;
    font-family: "CirrusCumulus", sans-serif;
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 110px;
        height: 100%;
        align-items: flex-end;
        bottom: 0;
    }
    .subtitles {
        font-size: 20px;
    }
}
