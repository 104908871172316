.nav {
    position: fixed;
    padding-top: 10px;
    padding-right: 20px;
    z-index: 10;
    font-family: "CirrusCumulus", sans-serif;
    font-size: 40px;
}

.home:hover,
.projects:hover,
.contact:hover {
    font-style: italic;
    cursor: pointer;
}
.home:focus,
.projects:focus,
.contact:focus {
    font-style: italic;
}

@media screen and (max-width: 768px) {
    .nav {
        font-size: 30px;
    }
}
