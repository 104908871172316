* {
    margin: 0;
}

.background {
    height: 100%;
    width: 100%;
    background-color: #190b28;
    color: #fdcff3;
}

@font-face {
    font-family: "CirrusCumulus";
    src: url("./fonts/cirruscumulus-master/fonts/CirrusCumulus.woff")
        format("woff");
}
