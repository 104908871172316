.container {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 25px;
    text-align: center;
    font-family: "Rajdhani", sans-serif;
    z-index: 7;
}

a {
    text-decoration: none;
    color: #7bc950;
    z-index: 300;
}

a:hover {
    font-style: italic;
    color: tomato;
}

.logo {
    height: 20px;
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 30%;
    }
}
