.container {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    font-family: "Rajdhani", sans-serif;
    overflow: hidden;
}

.project {
    font-size: 64px;
    text-align: center;
    padding: 1%;
    transition: all 0.3s ease;
}

.project:hover {
    color: tomato;
    font-size: 82px;
    transition: all 0.3s ease;
}
